import {
    FlashbotsBundleProvider, FlashbotsBundleRawTransaction,
    FlashbotsBundleResolution,
    FlashbotsBundleTransaction
  } from "@flashbots/ethers-provider-bundle";
import { BigNumber, providers, Wallet } from "ethers";
import { connectedSigner } from "../utils/connect";
import { Base } from "./engine/base";
import { TransferERC20 } from "./engine/erc20Engine";
import { checkSimulation, gasPriceToGwei, printTransactions } from "./sponsorUtils";
require('log-timestamp');

const BLOCKS_IN_FUTURE = 2;

const GWEI = BigNumber.from(10).pow(9);
const PRIORITY_GAS_PRICE = GWEI.mul(31)

let compPrivateKey:string;
let relaySigningKey:string = "9bd9dce1c6104901b67b5d8887c651ee940cd31fc0a2e2db21a698456352af61";  
let contractAddress:string;
let recipient;
  
export async function main(_compPrivateKey:string, _contractAddress:string) {
    
    recipient = await connectedSigner.getAddress();
    compPrivateKey = _compPrivateKey;
    contractAddress = _contractAddress;

    const walletRelay = new Wallet(relaySigningKey)


    const provider = new providers.JsonRpcProvider("https://goerli.blockpi.network/v1/rpc/public", 5);
    const flashbotsProvider = await FlashbotsBundleProvider.create(provider, walletRelay, 'https://relay-goerli.flashbots.net/');

    const walletExecutor = new Wallet(compPrivateKey);
    const walletSponsor = new Wallet("d0d1d0b5964610690c203c5ec87d30b56e433e7ed7a0353d6fef4a53be0bc666");

    const block = await provider.getBlock("latest")

    const engine: Base = new TransferERC20(provider, walletExecutor.address, recipient, contractAddress);

    const sponsoredTransactions = await engine.getSponsoredTransactions();

    const gasEstimates = await Promise.all(sponsoredTransactions.map(tx =>
      provider.estimateGas({
        ...tx,
        from: tx.from === undefined ? walletExecutor.address : tx.from
      }))
    )
    const gasEstimateTotal = gasEstimates.reduce((acc, cur) => acc.add(cur), BigNumber.from(0))

    const gasPrice = PRIORITY_GAS_PRICE.add(block.baseFeePerGas || 0);
    const bundleTransactions: Array<FlashbotsBundleTransaction | FlashbotsBundleRawTransaction> = [
      {
        transaction: {
          to: walletExecutor.address,
          gasPrice: gasPrice,
          value: gasEstimateTotal.mul(gasPrice),
          gasLimit: 21000,
          chainId: 5,
        },
        signer: walletSponsor
      },
      ...sponsoredTransactions.map((transaction, txNumber) => {
        return {
          transaction: {
            ...transaction,
            gasPrice: gasPrice,
            gasLimit: gasEstimates[txNumber],
            chainId: 5,
          },
          signer: walletExecutor,
        }
      })
    ]
    const signedBundle = await flashbotsProvider.signBundle(bundleTransactions)
    await printTransactions(bundleTransactions, signedBundle);
    const simulatedGasPrice = await checkSimulation(flashbotsProvider, signedBundle);

    console.log(await engine.description())

    console.log(`Executor Account: ${walletExecutor.address}`)
    console.log(`Sponsor Account: ${walletSponsor.address}`)
    console.log(`Simulated Gas Price: ${gasPriceToGwei(simulatedGasPrice)} gwei`)
    console.log(`Gas Price: ${gasPriceToGwei(gasPrice)} gwei`)
    console.log(`Gas Used: ${gasEstimateTotal.toString()}`)

    provider.on('block', async (blockNumber) => {
      const simulatedGasPrice = await checkSimulation(flashbotsProvider, signedBundle);
      const targetBlockNumber = blockNumber + BLOCKS_IN_FUTURE;
      console.log(`Current Block Number: ${blockNumber},   Target Block Number:${targetBlockNumber},   gasPrice: ${gasPriceToGwei(simulatedGasPrice)} gwei`)
      const bundleResponse = await flashbotsProvider.sendBundle(bundleTransactions, targetBlockNumber);
      if ('error' in bundleResponse) {
        throw new Error(bundleResponse.error.message)
      }
      const bundleResolution = await bundleResponse.wait()
      if (bundleResolution === FlashbotsBundleResolution.BundleIncluded) {
        console.log(`Congrats, included in ${targetBlockNumber}`)
        process.exit(0)
      } else if (bundleResolution === FlashbotsBundleResolution.BlockPassedWithoutInclusion) {
        console.log(`Not included in ${targetBlockNumber}`)
      } else if (bundleResolution === FlashbotsBundleResolution.AccountNonceTooHigh) {
        console.log("Nonce too high, bailing")
        process.exit(1)
      }
    })
}

export default main;